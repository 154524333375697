<template>
  <div class="m-feedback-360-list">
    <b-notification
      v-if="deleteError"
      type="is-danger"
      has-icon
      :aria-close-label="$t('generic.closeMessageButton')"
      role="alert"
    >
      {{ $t('feedback360.feedbackDeleteError') }}
    </b-notification>
    <b-table :data="feedbackRequests" :paginated="true" :per-page="20" :loading="loading" default-sort="name">
      <b-table-column field="id" label="ID" sortable v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column field="requestByUser" :label="$t('feedback360.requestBy')" sortable searchable v-slot="props">
        {{ props.row.requestByUser }}
      </b-table-column>

      <b-table-column field="subjectUser" :label="$t('feedback360.subject')" sortable searchable v-slot="props">
        {{ props.row.subjectUser }}
      </b-table-column>

      <b-table-column field="deadline" :label="$t('feedback360.deadline')" sortable searchable v-slot="props">
        <span class="tag">
          {{ props.row.deadline }}
        </span>
      </b-table-column>

      <b-table-column field="created" :label="$t('generic.createdDate')" sortable centered v-slot="props">
        <span class="tag">
          {{ props.row.created }}
        </span>
      </b-table-column>

      <b-table-column field="options" :label="$t('generic.options')" centered v-slot="props">
        <b-button type="is-danger" icon-right="trash" @click="confirmDelete(props.row.id, props.row.type)" />
      </b-table-column>
      <template slot="empty">
        <no-data-to-list />
      </template>
    </b-table>
  </div>
</template>
<script>
import * as actions from '../../../store/actions';
import * as namespaces from '../../../store/namespaces';
import logger from '../../../utils/logger';
import NoDataToList from '../_fragments/NoDataToList';

export default {
  name: 'Feedback360List',
  components: { NoDataToList },
  data() {
    return {
      isMenuActive: true,
      loading: false,
      loadingError: false,
      deleteError: false
    };
  },
  computed: {
    feedbackRequests() {
      let data = [];
      if (this.$store.state.feedback.feedbackRequestsAll) {
        this.$store.state.feedback.feedbackRequestsAll.forEach(item => {
          if (!item.subjectUser || !item.requestByUser) {
            return;
          }
          data.push({
            id: item._id,
            requestByUser: item.requestByUser ? item.requestByUser.nameLast + ' ' + item.requestByUser.nameFirst : '',
            subjectUser: item.subjectUser ? item.subjectUser.nameLast + ' ' + item.subjectUser.nameFirst : '',
            deadline: new Date(item.deadline).toLocaleDateString(),
            created: new Date(item.createdAt).toLocaleDateString()
          });
        });
      }
      return data;
    }
  },
  mounted() {
    this.loading = true;
    this.loadingError = false;
    this.reloadFeedback360();
  },
  methods: {
    reloadFeedback360() {
      this.loading = true;
      this.$store.dispatch(namespaces.FEEDBACK + actions.FETCH_FEEDBACK_REQUESTS).then(() => {
        this.loading = false;
      });
    },
    confirmDelete(feedbackRequestId) {
      this.$buefy.dialog.confirm({
        message: this.$t('feedback360.confirmDelete'),
        cancelText: this.$t('generic.cancelButton'),
        confirmText: this.$t('generic.yesButton'),
        focusOn: 'cancel',
        onConfirm: () => {
          this.$store
            .dispatch(namespaces.FEEDBACK + actions.DELETE_FEEDBACK_REQUEST, feedbackRequestId)
            .then(() => {
              this.deleteError = false;
            })
            .catch(error => {
              this.deleteError = true;
              logger.error(error);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
